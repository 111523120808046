<template>
    <div class="app-course">
        <van-cell-group class="my-group">
            <van-cell class="date" title="日期" icon="underway-o" :value="time" @click="openDate" is-link/>        
            <van-cell class="course"  title="课程" icon="award-o" @click="openCourse" :value="course.name" is-link/>
        </van-cell-group>

        <!-- 选择的上课时间和课程 -->
        <div class="course-time-list" v-show="selectCourseList.length !== 0">
            <div class="course-time-item" v-for="(item, i) in selectCourseList" :key="i">
                <div class="title">
                    <span class="name">时段{{(i+1) | CapParse}}</span>
                    <van-tag type="primary">{{item.type}}</van-tag>
                    <van-icon name="close" @click="delCourse(i, item)" />
                </div>
                <div class="item">
                    <div class="date">
                        <van-icon name="underway-o" />{{item.time}} ~ {{item.time | time}}
                    </div>
                    <div class="price">
                        <div>￥{{item.coursePrice}}/课x1</div>
                        <div class="color">{{item.realCoursePrice | money}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="coach">
                        <van-icon name="user-circle-o"/> {{item.coach}}
                    </div>
                    <div class="price">
                        <div>￥{{item.coachPrice}}/课x1</div>
                        <div class="color">{{item.realCoachPrice | money}}</div>
                    </div>
                </div>
                <van-cell title="使用课时" :value="item.userVourch === 0 ? '不使用' : '使用'" is-link @click="openVoucher(i)"/>
                <van-cell title="选择亲属" :value="item.relativeName ? item.relativeName : '暂无'" is-link @click="openRelation(i)"/>
            </div>
            <div class="btn" v-show="!showCourseTime">
                <van-button icon="plus" block round type="primary" @click="addCourseTime">添加时段</van-button>
            </div>

            <div class="xiaojie" v-show="!showCourseTime">
                <div class="item">
                    <span>总价</span>
                    <span>{{(totalPrice/100) | money}}</span>
                </div>
                <div class="item">
                    <span>课时</span>
                    <span>使用{{classNum}}鞍时抵扣{{deductionMoney}}元</span>
                </div>
                <div class="item">
                    <span>应付</span>
                    <span>{{realPay | money}}</span>
                </div>
                <div class="integral">
                    <div class="int"><span>总余额</span><span class="color">({{balanceTotal}})</span></div>
                    <div class="int"><span>使用余额</span><input class="input" type="number" ref="inputs" v-model="balance" @input="onChageInputBa"/></div>
                    <van-checkbox v-model="checkedBalance" @change="onChageInputBa"></van-checkbox>
                </div>

            </div>

            <div class="tip" v-show="!showCourseTime">
                <div class="title">
                    权益须知
                </div>
                <div class="text">
                    <p>1.至少提前三天预约，以提起支付教练费为准。（关注微信公众号和小程序）</p>
                    <p>2.会员课程请假或取消请在课程前12小时以上，否则会扣除当天课时，不限课时将扣除教练费用。</p>
                </div>
            </div>
        </div>

        <!-- 结算 -->
        <van-submit-bar v-show="!showCourseTime && selectCourseList.length !== 0" class="my-submit-bar" :price="realyPay*100" button-text="提交订单" @submit="onSubmitOrder" />

        
        <!-- 上课时段 -->
        <div class="course-time" v-show="showCourseTime">
            <div class="content">
                <div class="title">
                   <span>上课时段</span> 
                   <van-icon name="cross" @click="showCourseTime=false" v-show="showCourseTime && selectCourseList.length !== 0"/>
                </div>
                <div class="list">                                                                          
                    <div class="item" v-for="(item, i) in courseTimeList" :key="i" 
                        :class="{'active': item.select, 'disabled': (item.disabled || item.coachDisabled || !item.billTime)}" 
                        @click="selectTime(item)">
                        <div class="time">{{item.time}}</div>
                        <div class="price">{{item.price | money}}</div>
                    </div>
                </div>
                <div class="coach" @click="openCoach">
                    <div class="coach-select" v-if="coachSelect.name === ''">
                        <van-image :src="''">
                            <template v-slot:error>
                                <van-icon name="plus" />
                            </template>
                        </van-image>
                        <span>选择教练</span>
                    </div>
                    <div class="coach-selected" v-else>
                        <van-image :src="getImg(coachSelect.picUrl)" alt="">
                            <template v-slot:error><van-image :src="coach.imgUrl" alt=""/></template>
                        </van-image>
                        <div class="name-price">
                            <div class="name">
                                <span>{{coachSelect.name}}</span>
                                <van-tag type="primary">{{coachSelect.level}}</van-tag>
                            </div>
                            <div class="price">
                                <span>{{coachSelect.type}}{{coachSelect.price | money}} /课时</span> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn">
                    <van-button round type="primary" @click="submit" block>确认选择</van-button>
                </div>
            </div>
        </div>

        <!-- 日期 -->
        <van-popup v-model="showDate" class="my-popup" position="bottom" round>
            <div class="date_calendar">
                <div class="year_month">
                    {{year}}年{{month+1}}月
                </div>
                <!-- <div @touchstart="touchStart" @touchend="touchEnd"> -->
                <van-calendar
                    title="日历"
                    :poppable="false"
                    :show-confirm="false"
                    :min-date="minDate" 
                    :max-date="maxDate"
                    @select="selectDate"
                    :formatter="formatter"
                    ref="calender"
                />
                <!-- </div> -->
                <van-icon name="arrow" @click="nextMonth"/>
                <van-icon name="arrow-left" @click="proMonth"/>
            </div>
            <my-btn :btnStr="'确认'" @onSubmit="onSubmit" />
        </van-popup>

        <!-- 课程 -->
        <van-popup v-model="course.show" round position="bottom" class="my-course-popup">
            <van-search v-model="course.search" placeholder="请输入课程名称" @search="searchCourse" />
            <div style="height: 60vh; overflow: auto; background-color: #fff;">
                <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                    <van-list v-model="loading" :finished="finished" @load="onLoad1">
                        <div v-for="item in list" class="item" :key="item.id">
                            <div class="course-right">
                                <div class="name">{{item.curriculumName}}</div>
                                <div class="price" v-if="item.corpCurriculumVO">课时费 {{item.corpCurriculumVO.priceWork}}元/鞍时 ， 教练费另付</div>
                                <div class="btn">
                                    <!-- <van-tag type="primary">有0课时可使用</van-tag> -->
                                    <van-button type="primary" round size="mini" @click="selectCourse(item)">选择</van-button>
                                </div>
                            </div>
                        </div>
                        <no-more v-show="showNoMore && !loading"/>
                    </van-list>
                </van-pull-refresh>
            </div>
            <!-- 暂无数据 -->
            <no-data v-if="showData"/>
        </van-popup>

        <!-- 教练 -->
        <van-popup v-model="coach.show" round position="bottom" class="my-coach-popup">
            <van-search v-model="coach.search" placeholder="请输入搜索关键词" @search="searchCoach" />
            <div style="height: 60vh; overflow: auto; background-color: #fff;">
                <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                    <van-list v-model="loading" :finished="finished" @load="onLoad1">
                        <div v-for="item in list" class="item" :key="item.id">
                            <div class="coach-left">
                                <van-image class="coach-image" fit="cover" :src="getImg(item.picHeadUrl)" alt="">
                                    <template v-slot:error><van-image class="coach-image" fit="cover" :src="coach.imgUrl" alt=""/></template>
                                </van-image>
                                <!-- <van-image class="coach-image" fit="cover" :src="coach.imgUrl" alt=""/> -->
                            </div>
                            <div class="coach-right">
                                <div class="name">
                                    <span>{{item.coachName}}</span>
                                    <van-tag type="primary">{{item.coachLevelName}}</van-tag>
                                </div>
                                <div class="desc ">
                                    {{item.professionalProfile}}
                                   
                                </div>
                                <div class="money">
                                    <span v-if="item.coachPriceVO">教练费:￥{{item.coachPriceVO ? item.coachPriceVO.priceOut : ''}}</span>
                                </div>
                                <div class="btn">
                                    <van-button type="primary" round size="mini" @click="selectCoach(item)">预约课程</van-button>
                                </div>
                            </div>
                        </div>
                        <no-more v-show="showNoMore && !loading"/>
                    </van-list>
                </van-pull-refresh>
            </div>
            <!-- 暂无数据 -->
            <no-data v-if="showData"/>
        </van-popup>

        <!-- 课程套餐 -->
        <van-popup v-model="voucher.show" round position="bottom" class="my-voucher-popup">
            <div style="height: 60vh; overflow: auto; background-color: #fff; padding-bottom: 64px; border-radius: 28px 28px 0 0">
                <div class="my-pull-refresh">
                    <div v-for="item in cardList" class="item" :key="item.id">
                        <div class="coach-left">
                            <van-image class="voucher-image" fit="cover" :src="getImg(item.picUrl)" alt="">
                                <template v-slot:error><van-image class="voucher-image" fit="cover" :src="voucher.imgUrl" alt=""/></template>
                            </van-image>
                            <!-- <van-image class="voucher-image" fit="cover" :src="voucher.imgUrl" alt=""/> -->
                        </div>
                        <div class="coach-right">
                            <div class="name">
                                <span>{{item.cardName}}</span>
                                <van-tag type="primary">{{item.curriculumName | changeCurriculumName}}</van-tag>
                            </div>
                            <div class="content">
                                <div class="info color last">
                                    <div class="label">可用:</div>
                                    <div class="value">{{item.cardHoursSurplus}}鞍时</div>
                                </div>
                            </div>
                            <div class="btn">
                                <van-button type="primary" round size="mini" @click="selectVoucher(item)">使用课程套餐</van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 暂无数据 -->
            <no-data v-if="cardList.length === 0"/>
            <my-btn :btnStr="'取消使用课程套餐'" @onSubmit="cancelVoucher"/>
        </van-popup>

         <!-- 亲属约课 -->
        <van-popup v-model="relation.show" round position="bottom" class="my-relation-popup">
            <div style="height: 60vh; overflow: auto; background-color: #fff; padding-bottom: 64px; border-radius: 28px 28px 0 0">
                <div class="my-pull-refresh">
                    <div v-for="item in relationList" class="item" :key="item.id">
                        <div class="relation">
                            <div class="relation-avater">
                                <span>{{item.relativeName}}</span>
                            </div>
                            <div class="info horse">
                                <div class="label">亲属关系</div>
                                <div class="value">{{item.relativeShipName}}</div>
                            </div>
                            <div class="info">
                                <div class="label">亲属年龄</div>
                                <div class="value">{{item.relativeAge}}</div>
                            </div>
                            <div class="bottom">
                                <div class="btn" slot="bottom">
                                    <van-button round @click="selectRelation(item)">选择亲属</van-button>
                                </div> 
                            </div>
                            <div class="circle left-top"></div>
                            <div class="circle left-bottom"></div>
                            <div class="circle right-top"></div>
                            <div class="circle right-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 暂无数据 -->
            <no-data v-if="relationList.length === 0"/>
            <my-btn :btnStr="'取消选择亲属'" @onSubmit="cancelRelation"/>
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            balanceTotal: 0,
            checkedBalance: false,
            balance: 0,
            conversionBa: 1,
            time: '选择日期',
            selectedDate : '',
            model: {},
            timeList: [],
            cardList: [],
            relationList: [],
            courseTimeList: [
                {type: 'hour00', time: '0:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour01', time: '1:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour02', time: '2:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour03', time: '3:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour04', time: '4:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour05', time: '5:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour06', time: '6:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour07', time: '7:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour08', time: '8:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour09', time: '9:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour10', time: '10:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour11', time: '11:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour12', time: '12:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour13', time: '13:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour14', time: '14:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour15', time: '15:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour16', time: '16:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour17', time: '17:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour18', time: '18:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour19', time: '19:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour20', time: '20:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour21', time: '21:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour22', time: '22:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
                {type: 'hour23', time: '23:00', price: '', select: false, disabled: false, arr: [], billTime: '' },
            ],
            selectTimeList: [],
            timeRuleList: [],
            voucher: {
                show: false,
                search: '',
                imgUrl: require('@/assets/img/card.jpeg'),
            },
            selectCourseList: [
                // {type: '一对一', time: '13:00' , coursePrice: '980', coachPrice: '220', coach: '张三'},
                // {type: '一对一', time: '14:00' , coursePrice: '780', coachPrice: '220', coach: '李四'},
            ],
            course: {
                name: '选择课程',
                search: '',
                show: false,
                index: null,
                id: ''
            },
            coach: {
                imgUrl:  require('@/assets/img/jiaolian.jpg'),
                name: '',
                level: '二星级',
                type: '一对一',
                price: '222',
                show: false,
                coach: ''
            },
            coachSelect: {
                picUrl: '',
                id: '',
                name: '',
                level: '',
                type: '一对一 ',
                price: '',
                show: false,
            },
            relation: {
                show: false
            },
            courseList: [],
            showDate: false,

            showCourseTime: false,
            
            minDate: '',
            maxDate: '',
            month: '',
            day:0,
            year: '',
            date: '',
            defaultMemberName: '',
            memberId: ''
           
        }
    },
    computed: {
        totalPrice() {
            let total = 0
            this.selectCourseList.map(ele=>{
                total += (ele.coachPrice-0)+(ele.coursePrice-0)
            })
            return total*100
        },
        classNum() {
            let classNum = 0
            this.selectCourseList.map(ele=>{
                if(ele.userVourch === 1){
                    classNum++
                }
            })
            return classNum
        },
        deductionMoney() {
            let deductionMoney = 0
            this.selectCourseList.map(ele=>{
                if(ele.userVourch === 1){
                    deductionMoney += (ele.coursePrice-0)
                }
                if(ele.coachPriceFlag === 1){
                    deductionMoney += (ele.coachPrice-0)
                }
            })
            return deductionMoney
        },
        realPay() {
            return this.totalPrice/100 - this.deductionMoney
        },
        realyPay() {
            if(this.checkedBalance){
                return this.realPay - this.balance
            }else{
                return this.realPay
            }
            
        }
    },
    filters: {
        money(val) {
            let money = (val-0)
            return '￥' + money;
        },
        CapParse(value) {
            let changeNum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'], newNum = '',
            arr = value.toString().split('');
            arr[0] = parseInt(arr[0]) - 1;
            if (arr[0] == -1 && arr.length == 1) { return '零'; }
            if (arr.length > 1) {
                arr[1] = parseInt(arr[1]) - 1;
                if (!arr[0]) {
                    newNum = !arr[0] && arr[1] == -1 ? changeNum[9] : changeNum[9] + changeNum[arr[1]];
                } else {
                    newNum = (changeNum[arr[0]] + changeNum[9]) + (changeNum[arr[1]] ? changeNum[arr[1]] : '')
                }
            } else {
                newNum = changeNum[arr[0]];
            }
            return newNum;
        },
        time(val) {
            let str = val.split(':')[0] + ':' + '45';
            return str
        },
        //处理课程名称
        changeCurriculumName(str) {
            let newName = '';
            if(str.indexOf(',') !== -1){
                let str2 = str.split(','), nameList = [];
                str2.forEach(item=>{
                    if(item.indexOf('*##*') !== -1){
                        nameList.push(item.split('*##*')[1])
                    }else {
                        nameList.push(item)
                    }
                })
                newName = nameList.join()
            }else {
                if(str.indexOf('*##*') !== -1){
                    newName = str.split('*##*')[1]
                }else {
                    newName = str
                }
            }
            return newName
        }
    },
    methods:{
        onChageInputBa() {
            if(this.balanceTotal > this.realPay*this.conversionBa){
                //够
                if((this.$refs.inputs.value-0) >= this.realPay*this.conversionBa){
                    this.$refs.inputs.value = this.realPay*this.conversionBa
                    this.balance = this.realPay*this.conversionBa
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }else{
                //不够
                if((this.$refs.inputs.value-0) >= this.balanceTotal){
                    this.$refs.inputs.value = this.balanceTotal
                    this.balance = this.balanceTotal
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }
        },
        //判断时段是否已选中
        judge() {
            //debugger
            this.courseTimeList.forEach(parent=>{
                this.selectCourseList.forEach(child=>{
                    parent.disabled ? this.$set(parent, 'disabled', true) : this.$set(parent, 'disabled', parent.time === child.time && child.coachId.includes(parent.arr))
                }) 
            })
        },
        init() {
            this.initList()
        },
        formatter(day) {
            // 当前月份的日
            var a = [day.date.getFullYear(), day.date.getMonth() + 1, day.date.getDate()];
            for(var i = 0, len = a.length; i < len; i ++) {
                if(a[i] < 10) {
                    a[i] = '0' + a[i];
                }
            }
            let time = new Date(day.date).FormatShort()
            if(!this.timeList.includes(time)){
                day.type = 'disabled'
            }
            return day
        },
        //上个月
        proMonth(){
            this.month = this.date.getMonth()-1;
            this.date.setMonth(this.date.getMonth() - 1);
            this.getDate();
        },
        //下个月
        nextMonth(){
            this.date.setMonth(this.date.getMonth() + 1);
            this.month = this.date.getMonth()+1;
            this.getDate()
        },
        getDate(){
            this.year = this.date.getFullYear();
            this.month = this.date.getMonth();
            var days = new Date(this.year, this.month + 1, -1).getDate() + 1;
            this.minDate = new Date(this.year, this.month, 1)
            this.maxDate = new Date(this.year, this.month, days)
            this.getDateScheduling(new Date(this.minDate).FormatShort(), new Date(this.maxDate).FormatShort())
        },
        //打开日期
        openDate() {
            this.showDate = true
            if(this.timeList.includes(new Date().FormatShort())){
                this.selectedDate = new Date().FormatShort()
            }
        },
        //时间段排班
        initScheduling() {
            let selectTimeObj = {}
            this.selectTimeList.forEach(ele=>{
                if(this.selectedDate === ele.workDate){
                    selectTimeObj = ele
                }
            }) 
            this.courseTimeList.forEach(ele=>{
                this.$set(ele, 'disabled', false)
                this.$set(ele, 'coachDisabled', false)
                if(selectTimeObj[ele.type] === 0){
                    this.$set(ele, 'disabled', true)
                }
            })
        },
        //选择日期
        selectDate(day) {
            this.selectedDate = new Date(day).FormatShort()
            
        },
        //判断系统
        judgeSystem() {
            /* Start  判断手机是IOS还是安卓 */
            let u = navigator.userAgent;
            //判断是否安卓
            // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            // 判断是否IOS
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if(isIOS){
                let newDate = new Date(new Date().FormatShort().replace(/-/g, '/')).getTime();
                let selectedDate = new Date(this.selectedDate.replace(/-/g, '/')).getTime();
                if(newDate === selectedDate){
                    this.courseTimeList.map(ele=>{
                        if(new Date().getTime() > new Date(new Date().FormatShort().replace(/-/g, '/')+ ' ' + ele.time + ":00").getTime()){
                            this.$set(ele, 'disabled', true)
                        }
                    })
                }else{
                    this.courseTimeList.map(ele=>{
                        if(new Date().getTime() > new Date(new Date().FormatShort().replace(/-/g, '/')+ ' ' + ele.time + ":00").getTime()){
                            // this.$set(ele, 'disabled', false)
                            if(!ele.disabled) {
                                this.$set(ele, 'disabled', false)
                            }
                        }
                    })
                } 
                
            }else {
                if(this.selectedDate === new Date().FormatShort()){
                    this.courseTimeList.map(ele=>{
                        if(new Date().getTime() > new Date(new Date().FormatShort()+ ' ' + ele.time + ":00").getTime()){
                            this.$set(ele, 'disabled', true)
                        }
                    })
                }else{
                    this.courseTimeList.map(ele=>{
                        if(new Date().getTime() > new Date(new Date().FormatShort()+ ' ' + ele.time + ":00").getTime()){
                            if(!ele.disabled) {
                                this.$set(ele, 'disabled', false)
                            }
                        }
                    })
                } 
            }
            this.getTimeRule()
        },
        //确认日历
        onSubmit() {
            this.courseTimeList.forEach(ele=>{
                this.$set(ele, 'select', false)
            })
            if(this.selectedDate !== ''){
                if(this.selectCourseList.length !== 0) {
                    this.$help.confirm({
                        title: '提示',
                        message: '修改日期将要重新选择时段',
                    })
                    .then(() => {
                        this.showDate = false 
                        this.time = this.selectedDate
                        this.courseTimeList.forEach(parent=>{
                            this.$set(parent, 'select', false)
                            // this.selectCourseList.forEach(child=>{
                            //     // if(parent.disabled){
                            //         if(parent.time === child.time){
                            //             console.log(parent, child)
                            //             debugger
                            //             this.$set(parent, 'disabled', true) 
                            //         }
                            //     // }
                            // }) 
                        })
                        // debugger
                        // console.log(this.courseTimeList)
                        this.selectCourseList = []
                    })
                    .catch(() => {})
                }else{
                    this.showDate = false 
                    this.time = this.selectedDate
                }
                this.openCourseTime()
            }else{
                return this.$help.error('请选择日期')
            }
            this.initScheduling()
            this.judgeSystem()
        },
        //显示时间端
        openCourseTime() {
            if(this.time !== '选择日期' && this.course.name !== '选择课程'){
                this.showCourseTime = true
            }
        },
        //打开课程
        openCourse() {
            this.course.show = true
            this.model = {}
            this.$set(this.model, 'corpId', this.$route.query.id)
            this.api = 'member/base/curriculum/curriculum-listForCorp';
            this.init()
        },
        searchCourse() {
            this.model = {}
            this.$set(this.model, 'corpId', this.$route.query.id)
            this.$set(this.model, 'curriculumNameLike', this.course.search)
            this.api = 'member/base/curriculum/curriculum-listForCorp';
            this.init()
        },
        //选择课程
        selectCourse(item) {
            if(this.course.name !== '选择课程' && this.time !== '选择日期' && this.selectCourseList.length !== 0){
                this.$help.confirm({
                    title: '提示',
                    message: '修改课程将要重新选择时段',
                })
                .then(() => {
                    //this.selectCourseList = []
                    this.course.name = item.curriculumName
                    this.course.id = item.id
                    this.showCourseTime = true
                    this.courseTimeList.forEach(parent=>{
                        this.$set(parent, 'select', false)
                        this.selectCourseList.forEach(child=>{
                            if(parent.disabled){
                                if(parent.time === child.time){
                                    this.$set(parent, 'disabled', false) 
                                }
                            }
                        }) 
                    })
                    this.selectCourseList = []
                })
                .catch(() => {})
            }else{
                this.course.name = item.curriculumName
                this.course.id = item.id
                this.courseTimeList.forEach(ele=>{
                    this.$set(ele, 'price', item.corpCurriculumVO.priceWork)
                })
                this.openCourseTime()
            }
            //debugger
            this.getCardList()
            //console.log(item)
            this.course.show = false
            
        },
        //打开教练
        openCoach() {
            this.coach.show = true
            this.model = {}
            this.$set(this.model, 'corpId', this.$route.query.id)
            this.$set(this.model, 'prohibitDate', this.time)
            this.api = 'member/base/coach/coach-listForCorp'
            this.init()
        },
        searchCoach() {
            this.model = {}
            this.$set(this.model, 'corpId', this.$route.query.id)
            this.$set(this.model, 'prohibitDate', this.time)
            this.$set(this.model, 'coachNameLike', this.coach.search)
            this.api = 'member/base/coach/coach-listForCorp';
            this.init()
        },
        //选择教练
        selectCoach(item) {
            // console.log(item) 
            // 如果存在教练  切换了教练 但是他可能又换回来了
            // if(this.coachSelect.id !== ''){
            //     if(this.coachSelect.id !== item.id){
            //         this.courseTimeList.forEach(parent=>{
            //             // if(!item.id.includes(parent.arr)){
            //             //     this.$set(parent, 'disabled', false)
            //             // }
            //         })
            //     }
            // }
            // console.log(this.courseTimeList)
            this.$set(this.coachSelect, 'picUrl', item.picHeadUrl)
            this.$set(this.coachSelect, 'name', item.coachName)
            this.$set(this.coachSelect, 'level', item.coachLevelName)
            this.$set(this.coachSelect, 'price', item.coachPriceVO.priceOut)
            this.$set(this.coachSelect, 'id', item.id)
            this.coachPlanRules()
            this.coach.show = false 
        },
        //按照教练的排班重新规划上课时段
        coachPlanRules() {
            this.$help.loading()
            this.$api.post('member/base/coachWorkProhibit/coachWorkProhibit-show', {
                coachId: this.coachSelect.id,
                corpId: this.$route.query.id,
                prohibitDate: this.time
            }).then(res=>{
                // 0 可选  1 不可选
                // console.log(res)
                // console.log(this.courseTimeList)
                // debugger
                // if(res.id === null) return
                //取出不可选的时间段
                let list = []
                for(let i in res){
                    if(i.indexOf('hour') !== -1){
                        if(res[i] === 1){
                            list.push(i)
                        }
                    }
                }
                this.courseTimeList = this.courseTimeList.map(ele=>{
                    if(ele.coachDisabled) { //教练禁班
                        ele.coachDisabled = false
                    }
                    if(!ele.disabled){
                        if(list.includes(ele.billTime)) {
                            ele.coachDisabled = true
                        }
                    }
                    if(ele.coachDisabled || ele.disabled) {
                        ele.select = false 
                    }
                    return ele
                })
            }).finally(this.$help.loaded)
        },
        //同时也排除这个已经约了的课
        exclusionClass() {

        },
        //选择时间
        selectTime(item) {
            if(item.disabled || item.coachDisabled || !item.billTime){
                return
            }
            if(this.coachSelect.name === ''){
                // if(item.select){
                //     this.courseTimeList.forEach(ele=>{
                //         if(ele.time === item.time){
                //             this.$set(ele, "select", false)
                //         }
                //     })
                // }else{
                //     this.courseTimeList.forEach(ele=>{
                //         if(ele.time === item.time){
                //             this.$set(ele, "select", true)
                //         }
                //     })
                // }
                this.openCoach()
                return
            }
            if(item.select){
                this.courseTimeList.forEach(ele=>{
                    if(ele.time === item.time){
                        this.$set(ele, "select", false)
                    }
                })
            }else{
                this.courseTimeList.forEach(ele=>{
                    if(ele.time === item.time){
                        this.$set(ele, "select", true)
                    }
                })
            }
        },
        //选择时段btn
        addCourseTime() {
            let newArr = []
            newArr = this.courseTimeList.filter(ele=>{
                return ele.disabled
            })
            if(newArr.length === this.courseTimeList.length){
                this.$help.error('您预约的时段已满')
                return
            }
            this.showCourseTime = true
            this.courseTimeList.forEach(parent=>{
                this.$set(parent, 'select', false)
            })
        },
        //添加课程btn
        submit() {
            if(this.coachSelect.name === ''){
                this.$help.error('请先选择教练')
                return
            }
            let arr = []
            arr = this.courseTimeList.filter(ele=>{
                return ele.select
            })
            if(arr.length === 0){
                this.$help.error('请选择您上课的时间段')
                return
            }
            arr.forEach(ele=>{
                this.selectCourseList.push(
                    {type: this.coachSelect.type, billTime:ele.billTime, time: ele.time,  coursePrice: ele.price, realCoursePrice: ele.price,
                    classNum: 0, coachPrice: this.coachSelect.price, realCoachPrice: this.coachSelect.price, coachId: this.coachSelect.id,
                    coach: this.coachSelect.name, userVourch: 0}
                )  
                this.courseTimeList.map(child=>{
                    if(ele.type === child.type){
                        ele.arr.push(this.coachSelect.id)
                    }
                })
            })
            this.judge()
            //console.log(this.courseTimeList)
            if(this.realPay >  this.balanceTotal){
                this.balance = this.balanceTotal
            }else{
                this.balance = this.realPay
            }
            this.showCourseTime = false
        },
        //删除课程
        delCourse(i, item) {
            this.$help.confirm({
                title: '提示',
                message: '确定是否删除该时段',
            })
            .then(() => {
                let obj = this.selectCourseList[i]
                this.selectCourseList.splice(i, 1)
                this.courseTimeList.map((ele, index)=>{
                    if(ele.time === item.time){
                        this.$set(ele, 'select', false)
                        this.$set(ele, 'disabled', false)
                        //console.log(obj)
                        ele.arr.map((child, i)=>{
                            if(child === obj.coachId){
                                this.courseTimeList[index].arr.splice(i, 1)
                            }
                        })
                    }
                })
                if(this.selectCourseList.length === 0){
                    this.addCourseTime()
                }
                if(this.realPay >  this.balanceTotal){
                    this.balance = this.balanceTotal
                }else{
                    this.balance = this.realPay
                }
            })
            .catch(() => {})
        },
        //打开课程套餐
        openVoucher(i) {
            this.course.index = i
            this.voucher.show = true          
        },
        //搜索课程套餐
        searchVoucher() {

        },
        //选择课程套餐
        selectVoucher(item){
            //当有这个字段时候就说包含教练鞍时费
            if(item.coachPriceFlag === 1){
                this.selectCourseList[this.course.index].coachPriceFlag = 1
                this.selectCourseList[this.course.index].realCoachPrice = 0
            }
            if(!this.selectCourseList[this.course.index].cardId){
                this.selectCourseList[this.course.index].cardId = item.id
                this.cardList.forEach(parent=>{
                    if(parent.id === this.selectCourseList[this.course.index].cardId){
                        this.$set(parent, 'cardHoursSurplus', parent.cardHoursSurplus-1)
                    }
                })
            }
            this.selectCourseList[this.course.index].userVourch = 1
            this.selectCourseList[this.course.index].realCoursePrice = 0
            //console.log(this.selectCourseList)
            this.voucher.show = false
        },
        //取消使用课程套餐
        cancelVoucher() {
            let ele = this.selectCourseList[this.course.index]
            ele.userVourch = 0
            if(ele.coachPriceFlag === 1) {
                ele.coachPriceFlag = 0
            }
            this.cardList.forEach(parent=>{
                if(parent.id === ele.cardId){
                    this.$set(parent, 'cardHoursSurplus', parent.cardHoursSurplus+1)
                }
            })
            delete ele.cardId
            ele.realCoursePrice = ele.coursePrice
            ele.realCoachPrice = ele.coachPrice 
            //console.log(this.selectCourseList)
            this.voucher.show = false
        },

        //打开亲属卡
        openRelation(i) {
            this.course.index = i
            this.relation.show = true         
        },
        //选择亲属卡
        selectRelation(data) {
            this.selectCourseList[this.course.index].relativeId = data.id
            this.selectCourseList[this.course.index].relativeName = data.relativeName
            this.selectCourseList[this.course.index].relativeShip = data.relativeShip
            this.relation.show = false         
        },
        cancelRelation() {
            delete this.selectCourseList[this.course.index].relativeId
            delete this.selectCourseList[this.course.index].relativeName 
            delete this.selectCourseList[this.course.index].relativeShip
            this.relation.show = false  
        },

        //提交订单
        onSubmitOrder() {
            let form = {}
            form.curriculumId = this.course.id
            form.billDate = this.time
            form.corpId = this.$route.query.id
            if(this.checkedBalance){
                this.$set(form, 'balanceAmount', this.balance)
            }
            this.$set(form, 'payAmount', (this.realyPay-0).toFixed(2))
            let arr = []
            this.selectCourseList.forEach(ele=>{
                if(ele.cardId){
                    arr.push({
                        billCardId: ele.cardId,
                        coachId: ele.coachId,
                        billTime: ele.billTime,
                        relativeId: ele.relativeId,
                        relativeName: ele.relativeName,
                        relativeShip: ele.relativeShip,
                    })
                }else{
                    arr.push({
                        coachId: ele.coachId,
                        billTime: ele.billTime,
                        relativeId: ele.relativeId,
                        relativeName: ele.relativeName,
                        relativeShip: ele.relativeShip,
                    })
                }
            })
            this.$set(form, 'curriculumTimeVOList', arr)
            // console.log(form)
            this.$help.loading()
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
            .finally(this.$help.loaded)
        },
        getDateScheduling(workDateStart, workDateEnd) {
            let form = {
                corpId: this.$route.query.id,
                workDateStart: workDateStart,
                workDateEnd: workDateEnd
            }
            this.$api.post('member/base/corpWork/corpWork-findCorpWorkList', form).then(res=>{
                //console.log(res)
                this.selectTimeList = res
                if(res.length !== 0){
                    res.forEach(ele=>{
                        this.timeList.push(ele.workDate)
                    })
                }
            })
        },
        getCardList() {
            let model = {}
            this.$set(model, 'corpId', this.$route.query.id)
            this.$set(model, 'curriculumId', this.course.id)
            this.$api.post('member/bill/cardOrder/cardOrder-list-select', model).then(res=>{
                this.cardList = res
            })
        },
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then(() => {
                this.balanceTotal = this.$store.state.memberUser.amount
                this.memberId = this.$store.state.memberUser.id
                this.getrelativeList()
                if(this.$store.state.memberUser.memberName === this.defaultMemberName){
                    this.$help.confirm({
                        title: '提示',
                        message: '请完善个人信息',
                    })
                    .then(() => {
                        this.$router.push({path:'/center/realname'})
                    })
                    .catch(() => {})
                }
            })
        },
        changeName() {
            this.$api.post('/sys/sysProp/sysProp-show',{id: 'defaultMemberName'}).then(res=>{
                //console.log(res)
                this.defaultMemberName = res.propertyValue
                this.getMemberUser()
            })
        },
        getrelativeList() { 
            this.list = [] 
            this.$api.post('member/base/memberRelative/memberRelative-listForWx', {memberId: this.memberId}).then(res=>{
                //console.log(res)
                delete res.rootData
                res.map(ele=>{
                    this.relationList.push(ele)
                })
            })
        },
        getTimeRule() {
            this.$api.post('member/base/workRuleField/workRuleField-findWorkRuleFieldList').then(res=>{
                delete res.rootData
                res.map(ele=>{
                    this.timeRuleList.push(ele)
                })
                this.courseTimeList.map(parent=>{
                    this.timeRuleList.map(child=>{
                        if(parent.type === child.fieldName){
                            this.$set(parent, 'billTime', child.id)
                        }
                    })
                })
                // console.log(this.timeRuleList, this.courseTimeList)
                // debugger
            })
        }
    },
    mounted() {
        this.date = new Date()
        this.getDate()
        this.changeName()
        this.getTimeRule()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .app-course{
        .my-group{
            margin-bottom: 15px;
            .course{
                .van-cell__left-icon{
                    color: $yellow;
                }
            }  
            .date{
                .van-cell__left-icon{
                    color: $pink;
                }
            }
           
        }
        .my-popup{
            padding-top: 20px;
        }
        .date_calendar{
            position: relative;
            color: $main;
           padding-bottom: 64px;
            .year_month{
                line-height: 44px;
                text-align: center;
                background: #fff;
            }
            .van-calendar__header{
                .van-calendar__header-subtitle{
                    display: none;
                }
                .van-calendar__header-title{
                    display: none;
                }
                
            }
            .van-calendar__selected-day{
                background-color: $main;
            }
            .van-calendar__days{
                height: 380px;
            }
            .van-calendar__body{
                padding: 5px;
                .van-calendar__day{
                    //position: relative;
                    .van-calendar__bottom-info{
                        color: $green;
                        font-size: 20px;
                        font-weight: 700;
                        //position: absolute;
                        top: 35px !important;

                    }
                    // .van-calendar__selected-day{
                    //     .van-calendar__top-info{
                    //         color: #fff;
                    //         font-size: 20px;
                    //         font-weight: 700;
                    //     }
                    // }
                }
            }
            .van-icon{
                position: absolute;
            }
            .van-icon-arrow{
                top:10px;
                right:50px;
                font-size: 16px;
                padding:5px;
                background-color: #deedfc;
                border-radius: 50%;
                color: $main;
            }
            .van-icon-arrow-left{
                top:10px;
                left:50px;
                font-size: 16px;
                padding:5px;
                background-color: #deedfc;
                border-radius: 50%;
                color: $main;
            }
        }
        .my-course-popup{
            .my-pull-refresh{
                padding: 0 20px;
                .van-list{
                    margin-top: 20px;
                }
                .item{
                    margin-bottom: 20px;
                    border-radius: 9px;
                    box-shadow: 0 0 6px 9px $boxShadow;
                    padding: 10px;
                    .name{
                        font-size: 14px;
                        
                    }
                    .price{
                        color: $gray;
                    }
                    .btn {
                        display: flex;
                        justify-content: flex-end;
                        .van-button{
                            padding: 5px 10px;
                            background-color: $main;
                            border-color: $main;
                        }
                    }
                }
                
            }
            .no-data{
                top: 65%;
            }
        }
        .course-time{
            margin: 20px;
            background-color: #fff;
            border-radius: 9px;
            box-shadow: 0 0 6px 9px $boxShadow;
            .content{
                padding: 5px;
                .title{
                    font-size: 14px;
                    font-weight: bold;
                    padding: 5px;
                    display: flex;
                    justify-content: space-between;
                    i{
                        color: $pink;
                        font-size: 20px;
                    }
                }
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0 15px;
                    .disabled{
                        color: $gray !important;
                    }
                    .active{
                        background-color: $yellow;
                        border-radius: 9px;
                    }
                    .item{
                        padding: 5px 7px;
                        margin: 5px;
                        width: 48px;
                        .price{
                            color: $gray;
                        }
                        div{
                            text-align: center;
                        }
                    }
                }
                .coach{
                    &-select{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span{
                            color: $main;
                        }
                    }
                    &-selected{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .name{
                            span{
                                padding-right: 5px;
                            }
                        }
                        .price{
                            color: $gray;
                        }
                    }
                    .van-image{
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        margin-right: 5px;
                        .van-image__error{
                            border-radius: 50%;
                        }
                        img{
                            border-radius: 50%;
                        }
                    }
                    
                }
                .btn{
                    padding: 10px 20px;
                    .van-button{
                        background-color: $main;
                        border: 0;
                    }
                }
            }
        }
        .course-time-list{
            padding-bottom: 50px;
            .btn{
               padding: 10px 40px 20px; 
               .van-button{
                    background-color: $main;
                    border: 0;
                }
            }
            .course-time-item{
                margin: 20px;
                padding: 10px;
                box-shadow: 0 0 6px 9px $boxShadow;
                border-radius: 19px;
                background-color: #fff;
                .title{
                    text-align: center;
                    font-size: 15px;
                    padding-bottom: 10px;
                    position: relative;
                    border-bottom: 1px solid #ccc;
                    .van-icon{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        font-size: 20px;
                        color: $gray;
                    }
                    .name{ 
                        padding-right: 5px;
                    }
                }
                .item{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                    .color{
                        color: $red;
                        text-align: right;
                    }
                }
                .van-cell {
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                }
            }
            .xiaojie{
                margin: 10px 20px 20px;
                padding: 10px;
                background-color: #fff;
                border-radius: 19px;
                box-shadow:  0 0 6px 9px $boxShadow; 
                .item{
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                }
                .integral{
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;
                    .int{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .color{
                            color: $pink;
                        }

                    }
                    .input{
                        border: 0;
                        color: $pink;
                        outline: none;
                        border-bottom: 1px solid $pink;
                        width: 80px;
                        text-align: center;
                    }
                    .van-checkbox__icon--checked .van-icon{
                        background-color: $main;
                        border-color: $main;
                    }
                    // span{
                    //     color: $pink;
                    // }
                }
            }
            .tip{
                margin: 10px 20px 70px;
                padding: 10px;
                background-color: #fff;
                border-radius: 19px;
                box-shadow:  0 0 6px 9px $boxShadow; 
                color: #666;
                .title{
                    padding-bottom: 5px;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
        .my-coach-popup{
            .my-pull-refresh{
                padding: 0 20px;
                .van-list{
                    margin-top: 20px;
                }
                .item{
                    margin-bottom: 20px;
                    border-radius: 9px;
                    box-shadow: 0 0 6px 9px $boxShadow;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    .coach-left{
                        .coach-image{
                            width: 80px;
                            height: 80px;
                        } 
                        .voucher-image{
                            width: 100px;
                            height: 100px;
                        }
                    }
                    .coach-right{
                        padding-left: 20px;
                        min-width: 200px;
                        .content{
                            // display: flex;
                            // flex-wrap: wrap;
                            width: 180px;
                            .info{
                                padding: 5px 10px 0 0;
                                display: flex;
                            }
                        }
                    }
                    .name{
                        font-size: 14px;
                        .van-tag{
                            margin-left: 5px;
                        }
                    }
                    .desc{
                        color: $gray;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .btn {
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 20px;
                        .van-button{
                            padding: 5px 10px;
                            background-color: $main;
                            border-color: $main;
                        }
                    }
                }
            }
            .no-data{
                top: 65%;
            }
        }
        .my-voucher-popup{
            .my-pull-refresh{
                padding: 0 20px;
                margin-top: 20px;
                .item{
                    margin-bottom: 20px;
                    border-radius: 9px;
                    box-shadow: 0 0 6px 9px $boxShadow;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    .coach-left{
                        .coach-image{
                            width: 80px;
                            height: 80px;
                        } 
                        .voucher-image{
                            width: 100px;
                            height: 100px;
                        }
                    }
                    .coach-right{
                        padding-left: 20px;
                        .content{
                            // display: flex;
                            // flex-wrap: wrap;
                            width: 180px;
                            .info{
                                padding: 5px 10px 0 0;
                                display: flex;
                            }
                        }
                    }
                    .name{
                        font-size: 14px;
                        .van-tag{
                            margin-left: 5px;
                        }
                    }
                    .desc{
                        color: $gray;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .btn {
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 20px;
                        .van-button{
                            padding: 5px 10px;
                            background-color: $main;
                            border-color: $main;
                        }
                    }
                }
            }
            .no-data{
                top: 65%;
            }
        }
        .my-relation-popup{
            .my-pull-refresh{
                padding: 0 20px;
                margin: 20px 0;
                .item{
                    margin-top: 20px;
                }
                .relation{
                    box-shadow: 0 0 8px 6px $boxShadow;
                    border-radius: 9px;
                    padding: 10px;
                    position: relative;
                    overflow: hidden;
                    background-color: #fff;
                    &-avater{
                        display: flex;
                        align-items: center;
                        padding: 0 10px 11px;
                        .van-image__error{
                            border-radius: 50%;
                        }
                        .my-image{
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                            border: 1px solid $gray;
                            margin-right: 5px;
                            img{
                                border-radius: 50%;
                            }
                        }
                    }
                    &-code{
                        color: $gray;
                    }
                    .horse{
                        padding-top: 10px;
                        border-top: 1px dashed $gray;
                    }
                    .last{
                        padding-bottom: 10px;
                    }
                    .info{
                        display: flex;
                        justify-content: space-between;
                        line-height: 30px;
                        padding-left: 10px;
                        padding-right: 10px;
                        .label{
                            color: $gray;
                        }
                    } 
                    .color{
                        .value{
                            color: $main;
                        }
                    }   
                    .bottom{
                        padding-top: 9px;
                        border-top: 1px dashed $gray;
                        .btn{
                            display: flex;
                            justify-content: flex-end;
                            .van-button{
                                background-color: $main;
                                border: 0;
                                padding: 0 30px;
                                color: #fff;
                                height: 30px;
                                margin-right: 5px;
                            }
                        }
                        .tip{
                            padding: 5px 10px 5px 10px;
                            text-align: center;
                            color: $pink;
                            min-width: 100px;
                            max-width: 180px;
                        }
                    }
                    .circle{
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        border-radius: 50%;
                        background-color: $boxShadow;
                    }
                    .left-top{
                        top: 29px;
                        left: -10px;
                    }
                    .left-bottom{
                        bottom: 40px;
                        left: -10px;
                    }
                    .right-top{
                        top: 29px;
                        right: -10px;
                    }
                    .right-bottom{
                        bottom: 40px;
                        right: -10px;
                    }
                    .tip-status, .tip-status-success{
                        width: 50px;
                        border-radius: 50%;
                        border: 1px solid $red;
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        line-height: 50px;
                        text-align: center;
                        color: $red;
                        transform: rotate(45deg);
                        
                    }
                    .tip-status-success{
                        border: 1px solid $green !important;
                        color: $green !important;
                    }
                } 
        
            }
            .no-data{
                top: 65%;
            }
        }
        .my-submit-bar{
            .van-button{
                background: $main;
                border: 0;
            }
            .van-submit-bar__text{
                text-align: unset;
            }
        }

    }

</style>